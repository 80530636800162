import React from 'react'
import './ques.css'
import { Link } from 'react-router-dom'

const Message = () => {
  return (
    <div className="my-message">
        <div className="container">
            <div className='msg-cont'>
            <h1> السلام عليكم ورحمة الله وبركاته</h1>

 <h2> مجموعة بناء ترحب بكم وتقدم لكم مقياس "نعم أستطيع!"</h2>
<div>
مقياس يساعد الفرد على تحديد السلوكيات والمظاهر على التزامه بعناصر النجاح الخمسة (الثقة - المثابرة - الانسجام - المرونة العاطفية - التنظيم)، وذلك من خلال اسئلة يقوم بالإجابة عليها ذاتياً بالإضافة لآخرين ممن حوله ويقيمونه على نفس السلوكيات.
</div>
<div>
لا يستغرق هذا المقياس أكثر من 15 دقيقة تقريباً، وهو عبارة عن 30 سؤال يتم الاجابة عليها بالاختيار من 1 إلى 5 ، حيث 1 يمثل أن تكرار الصفة المسؤول عنها نادر لديك، بينما 5 يمثل أن الصفة دائمة التكرار لديك.
وحتى تكون نتيجة المقياس أقرب إلى الصحة فالرجاء مراعاة الأمور التالية:</div>

<div>1. هذا مقياس وليس اختبار، بمعنى؛ لا توجد إجابة صحيحة وأخرى خاطئة.</div>
<div>2. احرص على أن تكون هادئ النفس ومتزن وقت أداء المقياس، لا تكن في شدة غضب أو شدة فرح أو شدة حزن.</div>
<div>3. احرص على أن تكون في مكان هادئ بعيداً عن المقاطعات، إن كنت تستخدم الجوال لأداء المقياس فالرجاء وضعه على الصامت.</div>
<div>4. عند الإجابة حاول التركيز على حقيقة سلوكياتك وما أنت عليه بالفعل، وليس ما تتمناه أو ترى أنه الصواب، ولتكن إجابتك معبرة عن حقيقة سلوكك.</div>
<div>5. بعض الأسئلة ستقوم بالإجابة عليها بسرعة، بينما البعض الآخر ستحتار فيه، لا تفكر كثيراً واختر الأقرب حتى وإن كان الفرق بسيطاً.</div>
<div>
وأخيراً، كلما كانت إجاباتك دقيقة وأقرب إلى حقيقة سلوكياتك، فستجد أن النتيجة دقيقة للغاية، فاحرص على أن تكون إجاباتك دقيقة، وتخلص من المعوقات الاجتماعية أو غيرها.
</div>
<div>للإستفسار لا تتردد في التواصل على
</div>
<a href="wa.me/966596176440"> 966596176440</a>


<div>بالتوفيق</div>
<div>مجموعة بناء</div>
<div> فريق نعم أستطيع</div>

<Link className='next' to="/ques">التالي</Link>
            </div>
        </div>
    </div>
  )
}

export default Message