import React, { useEffect } from 'react'
import './home.css'
import Landing from '../landing/Landing';

function Home(props) {

  return (
    <div className="home">
        <Landing />
      
    </div>
  )
}

export default Home